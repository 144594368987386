<template>
  <v-dialog v-model="dialog" persistent v-if="item" :max-width="getDialogSize">
    <v-card>
      <v-form
        lazy-validation
        ref="form"
        v-model="isFormValid"
        @submit.prevent="submit"
      >
        <v-card-title class="d-flex justify-space-between grey lighten-2">
          <div class="d-flex flex-column text-center">
            <span class="subtitle-1">{{ `Order` }}</span>
            <span class="subtitle-2">{{ item.id }}</span>
          </div>
          <div class="d-flex flex-column text-center">
            <span class="subtitle-1">{{ `Customer` }}</span>
            <span class="subtitle-2">{{
              item.company_format.company.name
            }}</span>
          </div>
          <div class="d-flex flex-column text-center">
            <span class="subtitle-1">{{ `QTY` }}</span>
            <span class="subtitle-2">{{ item.total_quantity }}</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container v-if="item.state === 'CLOSED'">
            <v-row class="form">
              <v-col class="pt-5 d-flex justify-center">
                <v-btn class="mt-5" color="primary darken-1" @click="download">
                  {{ `Download and Invoice` }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="form" v-else>
            <v-radio-group v-model="order_type" @change="getSelectOptions">
              <v-row class="mt-n6" v-if="authuser.role !== `THIRDPARTY`">
                <v-col cols="6">
                  <v-radio
                    label="Third Party"
                    color="primary"
                    value="thirdparty"
                  ></v-radio>
                </v-col>
                <v-col cols="6">
                  <v-radio
                    label="Printer"
                    color="primary"
                    value="printer"
                  ></v-radio>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="mt-n2" cols="12">
                  <h4>Select Printer</h4>
                </v-col>
              </v-row>
            </v-radio-group>

            <v-row class="mt-n6">
              <v-col cols="6" v-if="authuser.role !== `THIRDPARTY`">
                <v-select
                  :items="getAllResponsible"
                  item-text="name"
                  item-value="id"
                  v-model="selectedId"
                  label="Third Party"
                  outlined
                  class="py-0"
                  :disabled="order_type !== `thirdparty`"
                ></v-select>
              </v-col>
              <v-col :cols="authuser.role === `THIRDPARTY` ? 12 : 6">
                <v-select
                  :items="getAllPrinters"
                  item-text="description"
                  item-value="id"
                  v-model="selectedId"
                  label="Printer"
                  outlined
                  class="py-0"
                  :disabled="isPrinterSelectDisabled"
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="mt-n6">
              <v-col cols="6" v-if="authuser.role !== `THIRDPARTY`"> </v-col>
              <v-col :cols="authuser.role === `THIRDPARTY` ? 12 : 6">
                <v-row
                  class="print-qty"
                  :class="authuser.role === `THIRDPARTY` ? `mx-0` : `mx-1`"
                >
                  <v-radio-group
                    v-model="qtyGroup"
                    class="m-0 p-0"
                    hide-details
                  >
                    <v-col>
                      <v-radio
                        value="all"
                        :label="`All`"
                        color="#9ac31c"
                        class="mb-0"
                        :disabled="isQtySectinDisabled"
                      ></v-radio>
                    </v-col>
                    <v-col class="d-flex flex-row align-center">
                      <v-radio
                        value="qty"
                        :label="`From`"
                        color="#9ac31c"
                        class="mb-0"
                        :disabled="isQtySectinDisabled"
                      >
                      </v-radio>
                      <v-text-field
                        type="number"
                        :rules="rulesQuantity"
                        v-model="qtyFrom"
                        outlined
                        dense
                        class="px-0 pl-2 mb-n7"
                        :disabled="isTextBoxDisabled"
                      ></v-text-field>
                      <span class="mx-2" :style="{ color: getColor }">to</span>
                      <v-text-field
                        type="number"
                        :rules="rulesQuantity"
                        v-model="qtyTo"
                        outlined
                        dense
                        class="p-0 w-100 mb-n7"
                        :disabled="isTextBoxDisabled"
                      ></v-text-field>
                    </v-col>
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            {{ `close` }}
          </v-btn>
          <v-btn
            class="mb-1"
            color="primary darken-1"
            v-if="item.state !== 'CLOSED'"
            @click="onPrint"
            :disabled="isPrintBtnDisabled"
          >
            {{ getSaveBtnText }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isFormValid: false,
      dialog: false,
      item: null,
      order_type: null,
      selectedId: null,
      qtyGroup: "all",
      qtyFrom: 1,
      qtyTo: 500,
      rulesQuantity: [
        (v) => v >= this.item.from_label || `min ${this.item.from_label}`,
        (v) =>
          v <= this.item.total_quantity || `max ${this.item.total_quantity}`,
      ],
    };
  },
  methods: {
    ...mapActions({
      printOrder: "order_print/printOrder",
      downloadInvoicedCsv: "order_print/downloadInvoicedCsv",
      printThirdpartyOrder: "order_print/printThirdpartyOrder",
    }),

    getSelectOptions() {
      this.selectedId = null;
      if (this.order_type === "printer") {
        this.$emit("fetch-printers");
      } else if (this.order_type === "thirdparty") {
        this.$emit("fetch-responsible");
      } else {
        return;
      }
    },
    download() {
      if (this.item.id) {
        this.downloadInvoicedCsv(this.item.id).then(() => {
          this.close();
        });
      }
    },
    async onPrint() {
      if (this.order_type === "") {
        return;
      }
      if (this.authuser.role === `THIRDPARTY`) {
        const params = {
          printorder_id: this.item.id,
          print_id: this.selectedId,
          from: Number(this.qtyFrom),
          to: Number(this.qtyTo),
        };
        if (this.qtyGroup === "all") {
          delete params.from;
          delete params.to;
        }
        this.printOrder(params)
          .then(() => {
            this.close();
          })
          .catch((e) => {
            console.error(e);
          });
      }
      if (this.order_type === "printer") {
        const params = {
          printorder_id: this.item.id,
          print_id: this.selectedId,
          from: Number(this.qtyFrom),
          to: Number(this.qtyTo),
        };
        this.printOrder(params)
          .then(() => {
            this.close();
          })
          .catch((e) => {
            console.error(e);
          });
      }
      if (
        this.order_type === "thirdparty" &&
        this.authuser.role !== `THIRDPARTY`
      ) {
        const payload = {
          responsible: this.selectedId && this.selectedId,
        };
        const data = this.selectedId
          ? { order_id: this.item.id, payload }
          : { order_id: this.item.id };
        this.printThirdpartyOrder(data)
          .then(() => {
            this.close();
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    open(item) {
      this.order_type =
        this.authuser.role === `THIRDPARTY` ? `thirdparty` : null;
      this.qtyGroup = "all";
      this.$forceUpdate();
      this.$emit("fetch-printers");
      this.item = { ...item };
      this.qtyFrom = item.from_label;
      this.qtyTo = item.total_quantity;
      this.dialog = true;
    },
    async close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$emit("refresh-printers");
      this.$emit("fetch-printers");
    },
  },
  computed: {
    ...mapGetters({
      getAllPrinters: "dashboard/getAllPrinters",
      getAllResponsible: "order_print/getAllResponsible",
      authuser: "auth/userdata",
    }),
    getDialogSize() {
      if (this.authuser.role === `THIRDPARTY`) {
        return 400;
      } else {
        return this.item.state === "CLOSED" ? 400 : 600;
      }
    },
    getSaveBtnText() {
      if (this.authuser.role === `THIRDPARTY`) {
        return `Print`;
      } else {
        return this.order_type === "thirdparty" ? `Save` : `Print`;
      }
    },
    isPrintBtnDisabled() {
      if (this.authuser.role === `THIRDPARTY`) {
        return !this.selectedId;
      } else {
        return (
          !this.order_type ||
          (this.order_type === `printer` && !this.selectedId) ||
          !this.isFormValid
        );
      }
    },
    isQtySectinDisabled() {
      if (this.authuser.role === `THIRDPARTY`) {
        return !this.selectedId;
      } else {
        return (
          !this.order_type || this.order_type !== `printer` || !this.selectedId
        );
      }
    },
    isTextBoxDisabled() {
      if (this.authuser.role === `THIRDPARTY`) {
        return this.qtyGroup !== "qty";
      } else {
        return (
          !this.order_type ||
          this.order_type !== `printer` ||
          !this.selectedId ||
          this.qtyGroup !== "qty"
        );
      }
    },
    getColor() {
      if (this.authuser.role === `THIRDPARTY`) {
        return this.qtyGroup === "qty" ? "#000" : "#aaa";
      } else {
        return !this.order_type ||
          this.order_type !== `printer` ||
          !this.selectedId ||
          this.qtyGroup !== "qty"
          ? "#aaa"
          : "#000";
      }
    },
    isPrinterSelectDisabled() {
      if (this.authuser.role === "THIRDPARTY") return false;
      else return this.order_type !== `printer`;
    },
  },
};
</script>
