<template>
  <div>
    <v-card flat color="accent" class="rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular title="Total ordernes" value="126"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>

    <h2 class="mx-6 my-6">Printers</h2>
    <!-- <v-form>
      <v-row class="mx-4 mt-6 d-inline-flex align-center" style="width: 100%">
        <v-col cols="12" md="2">
          <v-select
            :items="getAllStatus"
            item-text="name"
            item-value="value"
            label="Status"
            outlined
            dense
            v-model="status"
            class="py-0"
          ></v-select>
        </v-col>

        <v-col cols="12" md="2" v-if="authuser.role !== `THIRDPARTY`">
          <v-select
            :items="getAllResponsible"
            item-text="name"
            item-value="id"
            label="Responsible"
            v-model="responsible"
            outlined
            dense
            class="py-0"
          ></v-select>
        </v-col>

        <v-col cols="12" :md="date_time ? `4` : `2`">
          <VueCtkDateTimePicker
            label="Select date and time"
            v-model="date_time"
            color="#9ac31c"
            button-color="#9ac31c"
            format="YYYY-MM-DD HH:MM"
            range
            no-button
            no-shortcuts
            class="mt-n6"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            v-model="orderId"
            outlined
            label="Order"
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn class="mt-n8" color="primary" @click="searchQuery">
            {{ "Search" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form> -->

    <div v-if="printers">
      <v-data-table
        class="custom-table ma-5 mt-8"
        :headers="headers"
        :items="printers.results"
        :items-per-page="limit"
        :page.sync="page"
        :server-items-length="printers.count"
        item-class="py-4"
        @update:page="paginate"
        :footer-props="{
          itemsPerPageText: '',
          itemsPerPageOptions: [],
        }"
        :hide-default-footer="true"
        dense
      >
        <template v-slot:[`item.actions`]="props">
          <v-btn
            text
            color="primary"
            x-small
            @click="$refs.managePrintersDialogRef.open(props.item)"
          >
            Edit
          </v-btn>
          <v-btn
            text
            color="error"
            x-small
            @click="confirmDeleteController(props.item)"
          >
            Delete
          </v-btn>
        </template>
      </v-data-table>
      <v-row class="d-flex">
        <v-col class="mx-auto mb-6" cols="4">
          <v-container class="w-100" v-if="printers.count > limit">
            <v-pagination
              v-model="page"
              :length="Math.ceil(printers.count / limit)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </div>

    <v-fab-transition>
      <v-btn
        fab
        fixed
        right
        bottom
        color="primary"
        class="add-printer-btn"
        @click="$refs.managePrintersDialogRef.open()"
      >
        <v-icon color="#000" dark>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <manage-printers-dialog
      ref="managePrintersDialogRef"
      @refresh-printers="getPrinters"
    ></manage-printers-dialog>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/dist/moment";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import ManagePrintersDialog from "@/components/dialogs/ManagePrintersDialog.vue";

export default {
  components: { ConfirmDialog, ManagePrintersDialog },
  name: "printers",
  async mounted() {
    this.getPrinters();
  },
  data() {
    return {
      date_time: null,
      limit: 10,
      page: 1,
      params: {},
      orderId: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Description", value: "description" },
        { text: "IP", value: "ip" },
        { text: "Port", value: "port" },
        { text: "Mac", value: "mac" },
        { text: "Printer Type", value: "printer_type.name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions", align: "center" },
      ],
      status: null,
      responsible: null,
      getAllStatus: [
        { name: "ALL", value: null },
        { name: "ORDER", value: "ORDER" },
        { name: "ASIGNED", value: "ASIGNED" },
        { name: "WORKING", value: "WORKING" },
        { name: "VERIFIED", value: "VERIFIED" },
        { name: "CLOSED", value: "CLOSED" },
        { name: "INVOICED", value: "INVOICED" },
      ],
      options: {
        bgcolor: "error darken-3",
        color: "white",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchPrinters: "printers/fetchPrinters",
      deletePrinter: "printers/deletePrinter",
    }),
    getPrinters() {
      this.page = 1;
      this.fetchPrinters({
        limit: this.limit,
        offset: 0,
      });
    },
    setParams() {
      const params = {};
      params["id"] = this.orderId && this.orderId;
      params["state"] = this.status && this.status;
      params["responsible__id"] = this.responsible && this.responsible;
      params["creation_date__gt"] = this.date_time && this.date_time.start;
      params["creation_date__lte"] =
        this.date_time &&
        (this.date_time.end ||
          moment(this.date_time.start).endOf("day").format("yyyy-MM-DD HH:mm"));
      this.page = 1;
      this.params = { ...params, limit: this.limit, offset: this.page };
    },
    searchQuery() {
      this.setParams();
      this.fetchPrinters(this.params);
    },
    paginate(val) {
      this.page = val - 1;
      this.fetchPrinters({
        limit: this.limit,
        offset: this.page * this.limit,
      });
      this.page = val;
    },
    showManageBtn(state) {
      const check = ["ORDER", "PENDING"];
      return check.includes(state);
    },
    async confirmDeleteController(item) {
      if (
        await this.$refs.confirm.open(
          `Delete`,
          `Are you sure to delete <b>${item.description}</b>`,
          this.options
        )
      ) {
        this.deleteRecord(item.id);
      }
    },
    deleteRecord(id) {
      this.deletePrinter(id)
        .then(() => {
          this.getPrinters();
        })
        .catch((err) => console.log("Delete controller error :", err));
    },
  },

  computed: {
    ...mapGetters({
      printers: "printers/getPrinters",
      authuser: "auth/userdata",
    }),
  },
};
</script>

<style></style>
