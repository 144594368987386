<template>
  <div>
    <v-card flat color="accent" class="rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular title="Total ordernes" value="126"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>

    <v-form>
      <v-row class="mx-4 mt-6 d-inline-flex align-center" style="width: 100%">
        <v-col cols="12" md="2">
          <v-select
            :items="getAllStatus"
            item-text="name"
            item-value="value"
            label="Status"
            outlined
            dense
            v-model="status"
            class="py-0"
          ></v-select>
        </v-col>

        <v-col cols="12" md="2" v-if="authuser.role !== `THIRDPARTY`">
          <v-select
            :items="getAllResponsible"
            item-text="name"
            item-value="id"
            label="Responsible"
            v-model="responsible"
            outlined
            dense
            class="py-0"
          ></v-select>
        </v-col>

        <v-col cols="12" :md="date_time ? `4` : `2`">
          <VueCtkDateTimePicker
            label="Select date and time"
            v-model="date_time"
            color="#9ac31c"
            button-color="#9ac31c"
            format="YYYY-MM-DD HH:MM"
            range
            no-button
            no-shortcuts
            class="mt-n6"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            v-model="orderId"
            outlined
            label="Order"
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn class="mt-n8" color="primary" @click="searchQuery">
            {{ "Search" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <div v-if="printers">
      <v-data-table
        class="custom-table ma-5 mt-8"
        :headers="headers"
        :items="printers.results"
        :items-per-page="limit"
        :page.sync="page"
        :server-items-length="printers.count"
        item-class="py-4"
        @update:page="paginate"
        :footer-props="{
          itemsPerPageText: '',
          itemsPerPageOptions: [],
        }"
        :hide-default-footer="true"
        dense
      >
        <template v-slot:[`item.actions`]="props">
          <v-btn
            v-if="showManageBtn(props.item.state)"
            text
            x-small
            @click="$refs.orderPrintRef.open(props.item)"
          >
            Manage
          </v-btn>
          <v-btn
            v-if="props.item.state === `WORKING`"
            text
            x-small
            @click="confirmDeleteController(props.item)"
          >
            Cancel
          </v-btn>
        </template>
      </v-data-table>
      <v-row class="d-flex">
        <v-col class="mx-auto mb-6" cols="4">
          <v-container class="w-100" v-if="printers.count > limit">
            <v-pagination
              v-model="page"
              :length="Math.ceil(printers.count / limit)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </div>

    <manage-order-dialog
      ref="orderPrintRef"
      @refresh-printers="selectedItem = null"
      @fetch-printers="fetchAllPrinters"
      @fetch-responsible="fetchAllResponsible"
    ></manage-order-dialog>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ManageOrderDialog from "@/components/dialogs/ManageOrderDialog.vue";
import moment from "moment/dist/moment";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog.vue";

export default {
  components: { ManageOrderDialog, ConfirmDialog },
  name: "dashboard",
  async mounted() {
    this.fetchPrinters({
      limit: this.limit,
      offset: 0,
    });
    this.fetchAllResponsible();
  },
  data() {
    return {
      date_time: null,
      limit: 10,
      page: 1,
      params: {},
      selectedItem: null,
      orderId: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      headers: [
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "state",
        },
        { text: "Customer", value: "company_format.company.name" },
        { text: "Date", value: "formattedDate" },
        { text: "TagRef", value: "company_format.tag_type.reference" },
        { text: "Quantity", value: "total_quantity" },
        { text: "Responsible", value: "responsible.name" },
        { text: "Action", value: "actions", align: "center" },
      ],
      status: null,
      responsible: null,
      getAllStatus: [
        { name: "ALL", value: null },
        { name: "ORDER", value: "ORDER" },
        { name: "ASIGNED", value: "ASIGNED" },
        { name: "WORKING", value: "WORKING" },
        { name: "VERIFIED", value: "VERIFIED" },
        { name: "CLOSED", value: "CLOSED" },
        { name: "INVOICED", value: "INVOICED" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchPrinters: "dashboard/fetchPrinters",
      fetchAllPrinters: "dashboard/fetchAllPrinters",
      fetchAllResponsible: "order_print/fetchAllResponsible",
      cancelPrinting: "order_print/printThirdpartyOrder",
    }),
    setParams() {
      const params = {};
      params["id"] = this.orderId && this.orderId;
      params["state"] = this.status && this.status;
      params["responsible__id"] = this.responsible && this.responsible;
      params["creation_date__gt"] = this.date_time && this.date_time.start;
      params["creation_date__lte"] =
        this.date_time &&
        (this.date_time.end ||
          moment(this.date_time.start).endOf("day").format("yyyy-MM-DD HH:mm"));
      this.params = {
        ...params,
        limit: this.limit,
        offset: this.page * this.limit,
      };
    },
    selectOption(item, data) {
      this.selectedItem = item;
      if (this.selectedItem === "Manage") {
        this.$refs.orderPrintRef.open(data);
      }
    },
    searchQuery() {
      this.page = 0;
      this.setParams();
      this.fetchPrinters(this.params);
      this.page = 1;
    },
    paginate(val) {
      this.page = val - 1;
      this.setParams();
      this.fetchPrinters(this.params);
      this.page = val;
    },
    showManageBtn(state) {
      const check = ["ORDER", "PENDING", "CLOSED"];
      return check.includes(state);
    },
    async confirmDeleteController(item) {
      if (
        await this.$refs.confirm.open(
          `Confirm`,
          `Are you sure to Cancel <b>${item.company_format.company.name}</b>`,
          this.options
        )
      ) {
        this.cancel(item.id);
      }
    },
    cancel(id) {
      this.cancelPrinting({ order_id: id, payload: "" })
        .then(() => {
          this.$refs.confirm.cancel();
          this.status = null;
          this.page = 1;
          this.fetchPrinters({
            limit: this.limit,
            offset: 0,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },

  computed: {
    ...mapGetters({
      printers: "dashboard/getPrinters",
      getAllResponsible: "order_print/getAllResponsible",
      authuser: "auth/userdata",
    }),
  },
};
</script>

<style></style>
