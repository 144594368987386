<template>
  <div>
    <v-card flat color="accent">
      <v-row>
        <v-col md="2">
          <box-regular title="Total ordernes" value="126"></box-regular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "dashboard",
  async mounted() {},
  data() {
    return {
      headers: [
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "state",
        },
        { text: "Customer", value: "company_format.company.name" },
        { text: "Date", value: "formattedDate" },
        { text: "TagRef", value: "company_format.tag_type.reference" },
        { text: "Quantity", value: "total_quantity" },
        { text: "Action", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchPrinters: "dashboard/fetchPrinters",
    }),
  },
  computed: {
    ...mapGetters({
      printers: "dashboard/getPrinters",
    }),
  },
};
</script>

<style></style>
