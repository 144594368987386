<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-form
        lazy-validation
        ref="form"
        v-model="valid"
        @submit.prevent="submit"
      >
        <v-card-title>
          <span class="text-h5" v-if="input.id">Edit Printer</span>
          <span class="text-h5" v-else>Add Printer</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="form">
              <v-col cols="12">
                <v-text-field
                  :label="`IP*`"
                  class="my-0 py-0"
                  v-model="input.ip"
                  :rules="[validationRule.required, validationRule.ip_address]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`PORT*`"
                  class="my-0 py-0"
                  v-model="input.port"
                  :rules="[validationRule.required, validationRule.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`MAC*`"
                  class="my-0 py-0"
                  v-model="input.mac"
                  :rules="[validationRule.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`Description*`"
                  class="my-0 py-0"
                  v-model="input.description"
                  :rules="[validationRule.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Printer Type"
                  v-model="input.printer_type_id"
                  :items="printersType"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            Close
          </v-btn>
          <v-btn class="mb-1" color="primary darken-1" type="submit">
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  mounted() {
    this.fetchPrintersType();
  },
  data() {
    return {
      dialog: false,
      input: {},
      valid: true,
    };
  },
  methods: {
    ...mapActions({
      addUser: "users/addUser",
      updateUser: "users/updateUser",
      fetchPrintersType: "printers/fetchPrintersType",
      addPrinter: "printers/addPrinter",
      editPrinter: "printers/editPrinter",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        const params = {
          ip: this.input.ip,
          port: this.input.port,
          mac: this.input.mac,
          description: this.input.description,
          printer_type_id: this.input.printer_type_id,
          company_id: this.userdata.company,
        };
        if (this.input.id) {
          // call update printer api
          delete params.company_id;
          this.editPrinter({ params, id: this.input.id })
            .then(() => this.$emit("refresh-printers"))
            .catch((error) => console.log(error));
        } else {
          // call add printer api
          this.addPrinter(params)
            .then(() => this.$emit("refresh-printers"))
            .catch((error) => console.log(error));
        }
        this.close();
      }
    },
    open(item) {
      this.input = { ...item };
      if (item) {
        this.input.printer_type_id = this.input.printer_type.id;
      }
      this.dialog = true;
    },
    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    isBtnDisabled() {
      return (
        !this.valid &&
        !this.input.ip &&
        !this.input.port &&
        !this.input.mac &&
        !this.input.description &&
        !this.input.printer_type_id &&
        !this.input.company_id
      );
    },
  },
  computed: {
    ...mapGetters({
      printersType: "printers/getPrintersType",
      userdata: "auth/userdata",
    }),
  },
};
</script>
